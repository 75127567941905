import { mapKeys } from 'lodash'
import XLSX from 'xlsx'

export function FileToSet(f: File): Promise<Record<string, string>[]> {
  return new Promise((resolve, reject) => {
    try {
      const s = new FileReader()
      s.onload = (e) => {
        if (e?.target?.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer)
          const wb = XLSX.read(data, { type: 'array' })
          resolve(
            XLSX.utils
              .sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: false })
              .map((row: any) => {
                // trim whitespace from keys
                return mapKeys(row, (val: any, key: any) => {
                  return key.toString().trim()
                })
              })
              .map((row) => {
                // trim whitespace from values
                for (const key in row) {
                  row[key] = row[key].trim()
                }
                return row
              })
          )
        }
      }
      s.readAsArrayBuffer(f)
    } catch (e) {
      reject(e)
    }
  })
}
