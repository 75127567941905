import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import * as yup from 'yup'
import { postalCodeLooksValid } from '@/lib/support/utilities/address/postalCodeUtil'

export const phoneEnum = /[0-9(),-]+/
export const binaryChoice = ['YES', 'NO', 'TRUE', 'FALSE']
export const stateAbbreviations = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]
export const ziptest = yup
  .string()
  .test(
    'zip',
    '${path} is not a zip',
    (v: unknown) => postalCodeLooksValid(v as string) || v == '' || v == undefined
  )
  .transform((s) => new String(s).padStart(5, '0'))

export const ziptest_G2 = yup
  .string()
  .test(
    'zip',
    '${path} is not a zip',
    (v: unknown) => postalCodeLooksValid(v as string) || v == '' || v == undefined
  )
  .test('allRequired', 'If  Zip_G2 is provided, Address_G2, City_G2, and State_G2 are required', function () {
    if (!this.parent.Zip_G2) return true
    return this.parent.City_G2 && this.parent.Address_G2 && this.parent.State_G2
  })
  .transform((s) => new String(s).padStart(5, '0'))

export const ziptest_G3 = yup
  .string()
  .test(
    'zip',
    '${path} is not a zip',
    (v: unknown) => postalCodeLooksValid(v as string) || v == '' || v == undefined
  )
  .test('allRequired', 'If  Zip_G3 is provided, Address_G3, City_G3, and State_G3 are required', function () {
    if (!this.parent.Zip_G3) return true
    return this.parent.City_G3 && this.parent.Address_G3 && this.parent.State_G3
  })
  .transform((s) => new String(s).padStart(5, '0'))
export const daylist = days.map((x) => x.name)
export const yesno = yup.string().uppercase().oneOf(['YES', 'NO', 'TRUE', 'FALSE'])
