













































































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'

import dayjs from 'dayjs'

import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import SelectInput from '@/elements/SelectInput.vue'
interface PersonType {
  individualID: number
  lastName: string
  firstName: string
}

export default defineComponent({
  name: 'TeamInfo',
  components: {
    SelectInput,
  },
  props: {
    person: { type: Object as PropType<PersonType>, required: false },
    team: { type: Array as PropType<DivisionTeamInfo[]>, required: false },
  },
  setup(props) {
    const teamIndex = ref(0)
    const teams = computed(() => {
      return props.team?.map((x, i) => ({ id: i, team: x.teamName }))
    })

    function practice(team: DivisionTeamInfo): { dayTime: string; location: string } {
      let dayTime = ''
      let location = ''
      if (team) {
        const first = team.practices ? team.practices[0] : null
        const dt = first?.practiceStart ? dayjs(first.practiceStart) : null
        dayTime = dt ? dt.format('ddd h:mm a') : ''
        location = first?.facilityName ?? ''
      }

      return { dayTime, location }
    }

    return {
      teamIndex,
      teams,
      practice,
    }
  },
})
