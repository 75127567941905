






















import { defineComponent, PropType } from '@vue/composition-api'
export interface ImportErrorsType {
  line: number
  error: string
}
export default defineComponent({
  name: 'ImportErrorsTable',
  props: {
    import_errors: { required: true, type: Array as PropType<ImportErrorsType[]>, default: () => [] },
  },
})
