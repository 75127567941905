











import { defineComponent, PropType } from '@vue/composition-api'
import { ImportErrorsType } from '@/views/Programs/Participants/vues/ImportErrorsTable.vue'

export default defineComponent({
  name: 'ProgressIndicator',
  props: {
    import_errors: { type: Array as PropType<ImportErrorsType[]>, default: () => [], required: true },
    imported: { type: Number, required: true, default: 0 },
    saved: { type: Number, required: true, default: 0 },
    total: { type: Number, required: true, default: 0 },
  },
})
